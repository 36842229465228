<template>
  <div>
    <JobSearch> </JobSearch>
  </div>
</template>

<script>
import JobSearch from '@/pages/jobs/JobSearch';
import { jobSearchInit } from '@/pages/jobs/helper';
import { buildMetaHead } from '@/helpers/meta';

export default {
  name: 'PageJobsId',
  components: {
    JobSearch
  },
  head() {
    return buildMetaHead({
      title: 'Your dream startup job awaits you here!',
      description: this.queryIsRemote
        ? 'Work anywhere, anytime, work remotely. Browse 300+ remote startup jobs.'
        : 'Browse 2,000+ Nordic startup jobs, apply with ease and send applications directly to the company. Intern or senior, fulfil your dream career path here.',
      path: this.$route.path,
      imageUrl: process.env.BASE_URL + '/images/thehub_2022_find_startup_jobs.jpg'
    });
  },
  data: () => ({ countryCode: '' }),
  async asyncData({ app, store, query, error, params, route }) {
    return await jobSearchInit({ app, store, query, error, params, route });
  },
  computed: {
    queryIsRemote() {
      return this.countryCode === 'REMOTE' ? true : false;
    }
  },
  async mounted() {}
};
</script>

<style scoped lang="scss"></style>
