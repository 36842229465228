<template>
  <div class="view-job-find">
    <b-container>
      <b-row>
        <b-col class="pt-10 pb-20 py-lg-40">
          <SearchBar
            v-model="searchForm"
            search-placeholder="Job title or keyword"
            location-placeholder="City, area or select remote"
            light-shadow
            include-remote-in-country-selector
            :on-submit="onSearchBarSubmit"
          />
        </b-col>
      </b-row>
      <b-row ref="list" class="pb-60">
        <b-col lg="3">
          <div class="d-flex d-lg-block justify-content-between">
            <div>
              <SearchFilter
                :isLoading="isLoading"
                v-model="filters.roles"
                label="Roles"
                :options="jobRoles"
                :display-limit="24"
              />

              <SearchFilter
                :isLoading="isLoading"
                v-model="filters.positionTypes"
                label="Job types"
                :options="jobPositionTypes"
                value-key="id"
              />

              <!-- single-selection -->
              <SearchFilter
                :isLoading="isLoading"
                v-model="filters.countryCode"
                label="Remote"
                :options="isRemote"
                single-selection
              />

              <SearchFilter
                :isLoading="isLoading"
                v-model="filters.paid"
                label="Other"
                :options="[
                  {
                    label: 'Only show paid jobs',
                    value: 'true',
                    // count: 1
                    count: this.jobs.suggestions.paid
                  }
                ]"
              />
            </div>
          </div>
        </b-col>

        <b-col>
          <header
            class="
              d-block d-sm-flex
              align-items-center
              justify-content-between
              mt-16 mt-lg-0
              pt-16 pt-lg-0
              pb-4 pb-lg-10
              border-md-down-top
            "
          >
            <h6 class="pr-24 pb-16 pb-sm-0">
              <span class="hidden-md-down">Showing:</span>
              {{ jobs ? jobs.total : 0 }}
              <span class="hidden-md-down">filtered</span> jobs
            </h6>

            <JobsNewsletter v-if="jobs.docs.length" :filters="filters" />

            <dropdown
              :options="jobSorting.options"
              :selected="jobSorting.options[sorting]"
              @change="selectSorting"
            />
          </header>

          <section v-if="isLoading">
            <div
              v-for="skeleton in skeletons"
              :key="skeleton.id"
              class="my-10 job_skeleton-wrapper"
            >
              <div class="job_skeleton-info">
                <VueSkeletonLoader :width="48" :height="48" animation="fade" />
                <div class="job_skeleton-titles">
                  <VueSkeletonLoader :width="180" :height="20" animation="fade" :rounded="true" />
                  <VueSkeletonLoader :width="250" :height="14" animation="fade" :rounded="true" />
                </div>
              </div>
              <VueSkeletonLoader :width="28" :height="28" animation="fade" :rounded="true" />
            </div>
          </section>

          <content v-else>
            <SearchNoResults v-if="!jobs.docs.length" type="jobs" />
            <h6 v-if="!jobs.docs.length" class="pb-8">Showing latest jobs</h6>

            <div v-if="featuredJob && this.sorting !== 'newJobs'" class="my-10">
              <JobsListItem :job="featuredJob" is-featured />
            </div>
            <!-- <div v-if="featuredJob" class="my-10">
              <JobsListItem :job="featuredJob" is-featured />
            </div> -->

            <div v-for="job in list" :key="job.id" class="my-10">
              <JobsListItem :job="job" :is-featured="job.isFeatured" />
            </div>
          </content>

          <footer v-if="jobs && jobs.pages > 1" class="mt-40">
            <SearchPagination v-model="page" :result="jobs" />
          </footer>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import get from 'lodash/get';
import clone from 'lodash/clone';
import omitBy from 'lodash/omitBy';
import castArray from 'lodash/castArray';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import Dropdown from '@/components/Dropdown';
import SearchBar from '@/components/search/SearchBar';
import SearchFilter from '@/components/search/SearchFilter';
import JobsListItem from '@/components/jobs/JobsListItem';
import SearchNoResults from '@/components/search/SearchNoResults';
import SearchPagination from '@/components/search/SearchPagination';
import JobsNewsletter from '@/components/jobs/JobsNewsletter';
import VueSkeletonLoader from 'skeleton-loader-vue';
import { isEmptyDeep } from '@/helpers/helpers';

export default {
  name: 'PageJobs',
  components: {
    Dropdown,
    SearchBar,
    SearchFilter,
    JobsListItem,
    SearchPagination,
    SearchNoResults,
    JobsNewsletter,
    VueSkeletonLoader
  },
  props: {
    countryCode: {
      type: String,
      default: '',
      required: false
    },
    location: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    const countryCode =
      this.countryCode ||
      get(this.$route, 'query.countryCode') ||
      this.$store.getters['meta/sitePreferredCountryCode'];

    // console.log('this.countryCode', this.countryCode);
    // console.log('get(this.$route, "query.countryCode")', get(this.$route, 'query.countryCode'));
    // console.log(
    //   ' this.$store.getters["meta/sitePreferredCountryCode"]',
    //   this.$store.getters['meta/sitePreferredCountryCode']
    // );
    return {
      page: parseInt(get(this.$route, 'query.page', 1)),
      initialFilters: {
        positionTypes: [],
        roles: [],
        search: undefined,
        location: this.location,
        countryCode: countryCode,
        paid: undefined
      },
      filters: {
        positionTypes: castArray(get(this.$route, 'query.positionTypes', [])),
        roles: castArray(get(this.$route, 'query.roles', [])),
        search: get(this.$route, 'query.search'),
        location: get(this.$route, 'query.location') || this.location,
        paid: get(this.$route, 'query.paid'),
        countryCode: countryCode
      },
      searchForm: {
        search: get(this.$route, 'query.search'),
        location: get(this.$route, 'query.location') || this.location,
        countryCode: countryCode
      },
      sorting:
        get(this.$route, 'query.sorting') ||
        this.$cookies.get('jobSorting') ||
        this.$store.getters['meta/jobSorting'].default,
      isLoading: false,
      skeletons: [
        { id: 0 },
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
        { id: 11 },
        { id: 12 },
        { id: 13 },
        { id: 14 }
      ]
    };
  },
  computed: {
    params() {
      const params = omitBy(clone(this.filters), isEmptyDeep);
      if (this.page >= 2) {
        params.page = this.page;
      } else {
        delete params.page;
      }
      params.sorting = this.sorting;

      return params;
    },
    featuredParams() {
      const featuredParams = omitBy(clone(this.filters), isEmptyDeep);

      delete featuredParams.page;

      featuredParams.sorting = this.sorting;

      return featuredParams;
    },
    jobs() {
      // console.log('this.$store.state.jobs.jobs', this.$store.state.jobs.jobs);
      return this.$store.state.jobs.jobs || { total: 0, docs: [], suggestions: {} };
    },
    featuredJobs() {
      return this.$store.state.jobs.featuredJobs;
    },
    featuredJob() {
      return this.featuredJobs?.docs[this.featuredJobIndex] || '';
    },
    featuredJobIndex() {
      return this.$store.state.jobs.featuredJobs.randFeaturedIndex;
      // const randomIntFromInterval = (min, max) => {
      //   return Math.floor(Math.random() * (max - min + 1) + min);
      // };
      // return randomIntFromInterval(0, this.featuredJobs?.docs.length - 1);

      // let index = this.page || 0;
      // if (index >= 1) {
      //   index--;
      // }
      // return index;

      // return this.featuredJobs?.docs.length - 1;
    },
    latestJobs() {
      return this.$store.state.jobs.latestJobs?.docs || [];
    },
    list() {
      // return this.jobs.total
      //   ? this.jobs.docs.filter((job) => job.id != this.featuredJob.id)
      //   : this.latestJobs;
      return this.jobs.total ? this.jobs.docs : this.latestJobs;
    },
    isRemote() {
      return [
        {
          label: 'Remote jobs only',
          value: 'REMOTE',
          count: get(this.jobs, 'suggestions.remote', undefined)
        }
      ];
    },
    jobPositionTypes() {
      const jobPositionTypes = this.$store.getters['meta/jobPositionTypes'];

      return !this.jobs.total
        ? jobPositionTypes
        : jobPositionTypes.map((item) => ({
            ...item,
            count: get(this.jobs, 'suggestions.jobPositionTypes.' + item.id, undefined)
          }));
    },
    jobSorting() {
      return this.$store.getters['meta/jobSorting'];
    },
    jobRoles() {
      return !this.jobs.total
        ? this.$store.getters['meta/jobRoles']
        : this.$store.getters['meta/jobRoles'].map((item) => ({
            ...item,
            count: get(this.jobs, 'suggestions.jobRoles.' + item.value, undefined)
          }));
    }
  },
  watch: {
    'searchForm.countryCode'(newValue, oldValue) {
      /**
       * trigger search whenever we change the country.
       */
      console.log('country change', this.searchForm.countryCode);
      if (newValue !== oldValue) {
        this.filters.countryCode = this.searchForm.countryCode;
        this.filters.location = undefined;
        this.$store.dispatch('meta/setPreferredCountryCode', this.filters.countryCode);
      }
    },
    filters: {
      deep: true,
      handler(newVal, oldVal) {
        // console.log('handler', newVal, oldVal);
        // whenever a filter is changed, reset offset (page) to beginning
        if (newVal.page === oldVal.page) {
          this.page = 1;
        }

        if (this.filters.search !== this.searchForm.search) {
          this.searchForm.search = this.filters.search;
        }

        if (this.filters.location !== this.searchForm.location) {
          this.searchForm.location = this.filters.location;
        }

        // if (!this.filters.location) {
        //   this.filters.location = this.location;
        // }

        if (this.filters.countryCode === null) {
          this.filters.countryCode = this.$store.getters['meta/sitePreferredCountryCode'];
        }

        if (this.filters.countryCode !== this.searchForm.countryCode) {
          this.searchForm.countryCode = this.filters.countryCode;
        }

        this.doSearch();
      }
    },
    $route() {
      if (!isEqual(this.$route.query, this.params)) {
        this.filters = {
          ...this.initialFilters,
          ...this.$route.query
        };
      }
    },
    page() {
      this.doSearch();

      this.scrollToTop();
    },
    sorting(value) {
      this.$cookies.set('jobSorting', value);
    }
  },
  mounted() {
    // alert('mounted');
    // this.doSearch();
  },
  methods: {
    selectSorting(value) {
      if (value != this.sorting) {
        this.sorting = value;
        this.page = 1;
        this.doSearch();
      }
    },
    scrollToTop() {
      if (this.$refs.list && this.$refs.list.scrollIntoView) {
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    onSearchBarSubmit() {
      this.filters.search = this.searchForm.search;
      this.filters.location = this.searchForm.location;
      this.filters.countryCode = this.searchForm.countryCode;

      // when "submit" allow creation of new history items
      this.$store.dispatch('jobSearch/add', {
        params: this.params,
        createIfNotExists: true
      });

      this.doSearch();
    },
    doSearch: debounce(async function() {
      const asyncSearch = async () => {
        let params = { ...this.params };
        if (params.paid === 'false' || params.paid === false) {
          delete params.paid;
        }

        await this.$store.dispatch('jobs/getJobsAndFeatured', {
          ...params,
          includeSuggestions: true
        });

        // only allow update of existing history items
        this.$store.dispatch('jobSearch/add', {
          params: params,
          createIfNotExists: false
        });

        this.$router.push({
          query: params
        });
      };

      this.isLoading = true;
      setTimeout(async () => {
        await asyncSearch();
        this.isLoading = false;
      }, 0);
    }, 100)
  }
};
</script>

<style scoped lang="scss">
.view-job-find {
  background-color: $gray-100;
}

.job_skeleton-wrapper {
  * {
    cursor: unset !important;
  }

  padding: 1.5rem;
  width: 100%;
  height: 96px;
  background-color: white;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    height: 157px !important;
  }
}

.job_skeleton-info {
  display: flex;
  width: 100%;
}

.job_skeleton-titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-left: 1rem;
}

.filter_skeletons {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.filter_skeleton-header {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-bottom: 1rem;
  cursor: pointer;
}
.filter_skeleton-wrapper {
  * {
    cursor: unset !important;
  }
  margin-bottom: 1.25rem;
}

// .job_skeleton {
//   width: 100% !important;
// }
</style>
